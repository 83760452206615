<template>
	<div class="staffList" id="print">
		<div v-for="(item, index) in listData" :class="item.isactive ? 'listItem active' : 'listItem'" :key="index">
			<div class="content" @click="goDetail(item, index)" :style="{ cursor: buttonFlag ? 'pointer' : '' }">
				<span class="type_t type_tag" style="right: 5rem; background: rgb(233, 0, 0, 14%); color: #e90000" v-if="item.is_emphasis == 2">重点</span>
				<span :class="item.station_name == '运动员' ? 'type_t type_tag' : item.station_name == '教练' ? 'type_o type_tag' : 'type_th type_tag'">{{
					item.station_name !== '运动员' && item.station_name !== '教练' ? '其它' : item.station_name
				}}</span>
				<div style="height: 50%; display: flex">
					<div class="left-head-img">
						<img :src="item.head_img ? `http://${item.head_img}` : headImg" alt />
					</div>
					<div class="right-content">
						<div class="right_text">
							<p style="font-size: 14px; font-family: MicrosoftYaHei; line-height: 19px">
								<span style="font-family: MicrosoftYaHei; line-height: 24px; margin-right: 10px; font-size: 16px">{{ item.name }}</span>
							</p>
							<span>{{ item.sex == 1 ? '男' : '女' }}</span>
							<span>{{ item.birthday == 0 ? '' : item.birthday + '岁' || item.age }}</span>
						</div>
						<!-- <div class="right_1">
              <p v-for="(items, index) in contentText"
                v-if="item[items.field]"
                class="textHidden"
                :key="index">{{items.name + '：'}} {{item[items.field] ? item[items.field] : ''}}</p>
            </div>-->
					</div>
				</div>
				<div style="height: 50%" class="bot_cen">
					<p v-for="(items, index) in contentText" class="textHidden" :key="index">{{ items.name + '：' }} {{ item[items.field] ? item[items.field] : '' }}</p>

					<p v-if="item.station_name == '运动员'" class="omit _gray">教练： {{ item.coach_name ? item.coach_name : '' }}</p>
					<p v-if="item.station_name == '运动员'" class="omit _gray">项目： {{ item.sport_name ? item.sport_name : '' }}</p>
					<p class="omit _gray" v-if="item.is_out_cn == '离队'">离队： {{ item.is_out_cn ? item.is_out_cn : '' }}</p>
				</div>
			</div>
			<div class="bottom" v-if="!buttonFlag">
				<div v-for="(itemb, index) in buttonJson" class="button_item" v-show="!$store.getters.permissionsStr(itemb.Authority)" @click="goBackButton(itemb, item)" :key="index">
					{{ itemb.html }} {{ item.track_num ? '（' + item.track_num + '）' : '' }}
				</div>
			</div>
			<!-- <div class="checked">
        <el-checkbox @change="changeChe(item)"
                     v-model="item.checked"></el-checkbox>
      </div>-->
			<!-- <div class="grade"
           v-if='!buttonFlag || item.grade === 1  '
           :style="{'background': item.grade === 1 ? '#4BB3FF' : '#FC940A' }">
        {{item.grade === 1 ? '精英' : '普通'}}
      </div>
      -->
			<!-- <div class="delete"
           v-if="buttonFlag"
           @click="deleteClick(item)">
        <i class="el-icon-delete hoverlj"
           style=""></i>
      </div>-->
		</div>
		<div class="staf" slot="empty" v-if="this.listData == ''">
			{{ Empty }}
		</div>
	</div>
</template>

<script>
import img from '../../../static/head-default-big.jpg'

export default {
	name: 'staffList',

	props: ['listData', 'contentText', 'buttonJson', 'buttonFlag', 'staffLength'],

	data() {
		return {
			headImg: img,
			checkedArray: [],
			val: [],
			Empty: ' ',
			seleceObj: [],

			// isactive: false,
		}
	},

	watch: {
		listData: function(val) {
			this.seleceObj.forEach(item => {
				val.forEach(itm => {
					if(item.uuid == itm.uuid) {
						// itm.isactive = true
						this.$set(itm, 'isactive', true)
					}
				})
			})
		},
		staffLength: function(val) {
			this.$set(this, 'seleceObj', [])
			this.listData.forEach(item => {
				item.isactive = false
				this.$set(item, 'isactive', false)
			})
			this.$emit('go-detail', [])
		},
	},

	methods: {
		goDetail(item, index) {
			var type = false
			if(this.seleceObj.length) {
				this.seleceObj.forEach(im => {
					if(im.uuid == item.uuid) {
						type = true
					}
				})
			}
			if(type) {
				return false
			}
			// if (this.buttonFlag && !this.$store.getters.permissionsStr('查看')) {
			if (this.staffLength == 1) {
				this.seleceObj = []
				this.listData.forEach(item => {
					if (item.isactive) item.isactive = false
				})
				this.seleceObj.push(this.listData[index])
				this.$set(this.listData[index], 'isactive', true)
			} else {
				if(this.seleceObj.length == 2) {
					var obj = this.seleceObj[0]
					this.listData.forEach((itm, idx) => {
						if(itm.uuid == obj.uuid) {
							this.$set(this.listData[idx], 'isactive', false)
						}
					})
					this.seleceObj.splice(0, 1)
				}
				this.$set(this.listData[index], 'isactive', true)
				this.seleceObj.push(this.listData[index])
			}

			this.$emit('go-detail', this.seleceObj)
			// }
		},

		deleteClick(item) {
			if (!this.$store.getters.permissionsStr('删除')) {
				this.$emit('go-delete', item)
			}
		},

		goBackButton(item, res) {
			this.$emit('go-Back', item, res)
		},

		changeChe(item) {
			if (item.checked) {
				this.checkedArray.push(item.uuid)
				this.val.push(item)
			} else {
				let index = this.checkedArray.indexOf(item.uuid)
				this.checkedArray.splice(index, 1)
				this.val.splice(index, 1)
			}

			this.$emit('change-check', this.val, this.checkedArray)
		},
	},

	mounted() {
		// console.log(this.$store.getters.permissionsStr('查看'))
		setTimeout(() => {
			this.Empty = '暂无数据！'
		}, 2000)
	},
}
</script>

<style lang="scss" scoped>
.delete {
	position: absolute;
	bottom: 15px;
	right: 15px;
	cursor: pointer;
	font-size: 20px;
}

.hoverlj {
	color: #ccc;
}

.hoverlj:hover {
	color: #ff6666;
}

.staffList {
	width: 100%;
	min-height: 550px;
	// display: flex;
	// flex-wrap: wrap;
	overflow: hidden;
	padding-top: 10px;
	display: flex;
	flex-wrap: wrap;
	.listItem > :hover {
		box-shadow: 0 10px 15px rgba(3, 27, 78, 0.06);
	}

	.active {
		border-color: #0055e9 !important;
	}

	.listItem {
		width: 23.5%;
		height: 260px;
		box-shadow: 0px 2px 4px 3px rgba(224, 224, 224, 0.5);
		border-radius: 16px;
		border: 2px solid rgba(231, 228, 228, 0.842);
		margin: 0.5%;
		display: flex;
		flex-direction: column;
		position: relative;
		float: left;
		overflow: hidden;

		&:hover {
			border-color: #0055e9;
		}

		.grade {
			position: absolute;
			top: 35px;
			right: 10px;
			color: #fff;
			font-size: 12px;
			width: auto;
			min-width: 40px;
			padding: 2px 5px;
			line-height: 20px;
			border-radius: 20px;
			text-align: center;
		}

		.checked {
			position: absolute;
			top: 10px;
			right: 10px;
		}

		.content {
			flex: 1;
			position: relative;

			.type_o {
				background: rgba($color: #f6c218, $alpha: 0.14);
				color: #f18033;
			}
			.type_t {
				background: rgba($color: #0055e9, $alpha: 0.14);
				color: #0055e9;
			}
			.type_th {
				background: rgba($color: #4dff67, $alpha: 0.14);
				color: #18c431;
			}

			> span {
				position: absolute;
				top: 15px;
				right: 20px;
				padding: 5px 12px;
				font-size: 16px;
				font-weight: 400;
				border-radius: 20px;
			}
			.left-head-img {
				width: 90px;
				height: 90px;
				text-align: center;
				margin: 20px 30px;
				border-radius: 50%;
				overflow: hidden;
				> img {
					width: 100%;
					height: 100%;
				}
			}

			.bot_cen {
				background: rgba($color: #d8d8d8, $alpha: 0.2);
				padding-left: 25px;
				box-sizing: border-box;
				padding-top: 15px;
				.textHidden {
					color: #666;
				}
				p {
					margin-bottom: 10px;
				}
			}

			.right-content {
				flex: 1;
				margin-top: 30px;
				width: 30%;

				.right_text {
					p {
						margin-top: 16px;
						margin-bottom: 5px;
					}
					> span {
						margin-right: 10px;
						font-size: 15px;
						color: #666;
					}
				}

				.right_1 {
					margin-top: 20px;
					line-height: 30px;
					font-size: 14px;
					font-family: MicrosoftYaHei;
					color: rgba(98, 98, 98, 1);
				}
			}
		}

		.bottom {
			width: 100%;
			height: 60px;
			line-height: 60px;
			display: flex;
			background: #f9fafc;
			border-top: 1px solid rgb(230, 232, 239);
			border-radius: 0 0 5px 5px;
			.button_item {
				flex: 1;
				text-align: center;
				font-size: 15px;
				color: #8b8d91;
				cursor: pointer;
			}
			.button_item:hover {
				color: #fff;
				background: #4bb3ff;
			}
		}
	}
	.omit {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	._gray {
		color: #666 !important;
	}
}

.staf {
	width: 100%;
	min-height: 550px;
	background-color: #fff;
	text-align: center;
	line-height: 550px;
	color: #6666;
}
</style>
