<template>
  <div class="box">
    <el-dialog
      @close="searchVal=''"
      style=" text-align:center;font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;font-size: 20px;"
      title="选择指标"
      :visible.sync="$parent.dialog_type"
    >
      <div class="inner">
        <div class="leftSelect">
          <el-input
            placeholder="请输入指标名称"
            style="margin-bottom:20px;width:85%;"
            @input="search"
            v-model="searchVal"
            suffix-icon="iconfont iconsousuo"
            clearable
          ></el-input>
          <div class="head_Text">指标列表</div>
          <ul class="leftUl" style="height:270px;overflow:auto">
            <template v-for="(item,index) in $parent.zbList">
              <li style="line-height:30px" v-if="item.isShow" :key="index">
                <el-checkbox @change="$forceUpdate()" v-model="item.isCheck" :disabled="item.use_num !== 0 && item.isCheck"></el-checkbox>
                <div>{{item.cn_name}}</div>
              </li>
            </template>
          </ul>
        </div>
        <div class="rightSelect">
          <div class="title">
            <!-- <div
              class="titleCenter"
            >已选中{{($parent.zbList.filter(v=>v.isCheck)).length?($parent.zbList.filter(v=>v.isCheck)).length:''}}/{{$parent.zbList.length}}</div>-->
            <div
              class="titleCenter"
            >已选指标({{($parent.zbList != undefined && $parent.zbList.filter(v=>v.isCheck)).length}}/{{$parent.zbList != undefined && $parent.zbList.length}})</div>
            <!-- <div
              class="clean"
              @click="$parent.zbList.map(item=>item.isCheck = false);$forceUpdate()"
            >清空</div> -->
          </div>
          <ul class="leftUls" style="height:320px;overflow:auto">
            <template v-for="(item,index) in $parent.zbList">
              <li style="line-height:35px;" v-if="item.isCheck" :key="index">
                <div>{{item.cn_name}}</div>
                <div class="icon_text"
                    v-if="item.use_num == 0"
                >
                  <i
                    style="line-height:30px;"
                    @click="item.isCheck=false;$forceUpdate()"
                    class="el-icon-circle-close"
                  ></i>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <p class="fer-tips">* 此分类可增加指标，但已用指标不可删除！</p>
      <div class="form-bottom-button">
        <!-- <el-button type="primary" v-no-more-click @click="save">保存</el-button>
        <el-button @click="$parent.dialog_type=false">关闭</el-button>-->
        <el-button type="primary" v-no-more-click @click="save" class="save_btns" round>确 定</el-button>
        <el-button @click="$parent.dialog_type=false" class="cancel_btn" round>取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["defaultList"],
  data() {
    return {
      searchVal: ""
    };
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    save() {
      this.$parent.save();
    },
    search() {
      this.$parent.zbList.map(item => {
        if (item.cn_name.indexOf(this.searchVal) != -1) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.inner {
  display: flex;
  justify-content: space-around;

  .leftUl {
    // border: solid 1px #666;
    li {
      display: flex;
      margin-left: 20px;
      div {
        margin-left: 20px;
        color: #333333;
      }
    }
  }
  .leftUls {
    li {
      display: flex;
      margin-left: 20px;
    }
    div {
      color: #333333;
    }
  }

  .leftSelect,
  .rightSelect {
    width: 40%;
    border: 1px solid #e3e3e3;
  }
  .head_Text {
    height: 40px;

    background: #f5f5f5;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    text-align: center;
  }
  .leftUls li {
    display: flex;
    justify-content: space-between;
  }
  .leftUl,
  .rightSelect {
    .title {
      height: 40px;
      background-color: #e3e3e3;
      position: relative;
      .titleCenter {
        width: 100%;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        background: #f5f5f5;
      }

      .clean {
        position: absolute;
        top: 9px;
        right: 20px;
        color: #999999;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
/deep/ .el-dialog__header .el-dialog__title {
  line-height: 1.25rem;
  font-size: 0.9375rem;
  color: #303133;
  font-weight: 600;
}
.icon_text {
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.icon_text :hover {
  color: red;
}
.save_btns {
  width: 100px;
  background: #0055e9;
  color: #fff;
  margin-right: 110px;
}
.fer-tips {
  line-height: 30px;
  color: rgb(223, 9, 9);
}
</style>
