<template>
    <div class="athleteInfo" v-if="athleteInfo && athleteInfo.name">
        <el-avatar :size="50" :src="athleteInfo.head_img" v-show="athleteInfo.head_img != ($store.state.url+'/')"></el-avatar>
        <el-avatar :size="50" :src="'/dist/static/head-default.jpg'" v-show="athleteInfo.head_img == ($store.state.url+'/')"></el-avatar>
        <div class="athleteInfoItem">姓名：{{athleteInfo.name}}</div>
        <div class="athleteInfoItem">性别：{{athleteInfo.sex==1?"男":"女"}}</div>
        <div class="athleteInfoItem">年龄：{{athleteInfo.birthday}}</div>
        <el-tooltip class="item" effect="dark" :content="athleteInfo.department_name" placement="top">
            <div class="athleteInfoItem">运动队：{{athleteInfo.department_name}}</div>
        </el-tooltip>
        <div v-show='athleteInfo.is_athlete == 1' class="athleteInfoItem">项目：{{athleteInfo.sport_name }}</div>
        <div v-show='athleteInfo.is_athlete == 1' class="athleteInfoItem">教练：{{athleteInfo.coach_name}}</div>
    </div>
</template>

<script>
export default{
	data(){
		return {
		}
	},
	created(){

    },
    computed:{
        athleteInfo(){
            return this.$store.state.basicInfoVar
        }
    }

}
</script>
<style lang="scss" scoped>
.topBasicInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .athleteInfo {
        flex: 1;
        flex-wrap: wrap;
        display: flex;
        justify-content: start;
        align-items: center;
        .athleteInfoItem {
            margin-left: 30px;
            color: #626262;
        }
    }
    .buttonWrap {
        .buttonText {
            padding: 0 0 0 6px;
            color: #888;
        }
    }
}
</style>
