import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/*
    防止路由重复跳转报错
*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	// {
	// 	path: '/pm5Master/:id',
	// 	name: 'pm5Master',
	// 	component: resolve => require(['./../../public/pm5-base-master/index.html'], resolve),
	// },
	{
		path: '/',
		name: 'home',
		redirect: '/management/architecture',
		meta: {
			title: '',
		},
		component: resolve => require(['@/views/home.vue'], resolve),
		children: [
			{
				path: '/VersionDescription',
				name: 'VersionDescription',
				component: resolve => require(['@/views/VersionDescription.vue'], resolve),
			},
			{
				path: '/VersionDescriptionNX',
				name: 'VersionDescriptionNX',
				component: resolve => require(['@/views/VersionDescriptionNX.vue'], resolve),
			},
			// 首页
			{
				path: '/index',
				name: 'index',
				// meta: ['首页'],
				component: resolve => require(['@/views/pages/index'], resolve),
			},
			/*组织结构*/
			{
				path: '/management/architecture',
				name: 'architecture',
				component: resolve => require(['@/views/management/architecture'], resolve),
				meta: ['人事管理', '组织结构'],
			},

			/*设备数据接入*/
			{
				path: '/management/deviceAccess',
				name: 'deviceAccess',
				component: resolve => require(['@/views/management/deviceAccess'], resolve),
				meta: ['设备数据', '数据接入'],
			},

			// 设备管理--
			{
				path: '/management/device',
				name: 'managementDevice',
				component: resolve => require(['@/views/device/device'], resolve),
				meta: ['设备管理', '数据接入'],
				children: [
					{
						path: '/management/funcEquipment',
						name: 'funcEquipment',
						component: resolve => require(['@/views/device/funcEquipment'], resolve),
						meta: ['设备管理', '数据接入', '机能设备'],
					},
					{
						path: '/management/PhysEquipment',
						name: 'PhysEquipment',
						component: resolve => require(['@/views/device/PhysEquipment'], resolve),
						meta: ['设备管理', '数据接入', '体能设备'],
					},
					{
						path: '/management/deviceClassification',
						name: 'deviceClassification',
						component: resolve => require(['@/views/device/deviceClassification'], resolve),
						meta: ['设备管理', '数据接入', '设备分类'],
					},
					{
						path: '/management/physClassification',
						name: 'physClassification',
						component: resolve => require(['@/views/device/physClassification'], resolve),
						meta: ['设备管理', '数据接入', '设备分类'],
					},
				],
			},

			// 设备数据管理
			{
				path: '/DataDocking/dataAccess',
				name: 'dataAccess',
				component: resolve => require(['@/views/DataDocking/docking'], resolve),
				meta: ['设备数据管理', '数据接入'],
				children: [
					{
						path: '/DataDocking/functionalMent',
						name: 'functionalMent',
						component: resolve => require(['@/views/DataDocking/funcEquipment'], resolve),
						meta: ['设备数据管理', '数据接入', '机能设备'],
					},
					{
						path: '/DataDocking/PhysicalMent',
						name: 'PhysicalMent',
						component: resolve => require(['@/views/DataDocking/PhysEquipment'], resolve),
						meta: ['设备数据管理', '数据接入', '体能设备'],
					},
				],
			},
			// 设备数据管理 -- 机能数据查看
			{
				path: '/DataDocking/EquipmentLook',
				name: 'EquipmentLook',
				component: resolve => require(['@/views/DataDocking/EquipmentLook'], resolve),
				meta: ['设备数据管理', '数据接入', '机能设备', '查看'],
			},

			// 人事总览
			{
				path: '/management/personnelImproved',
				name: 'personnelImproved',
				component: resolve => require(['@/views/management/personnelImproved'], resolve),
				meta: ['人事管理', '人事总览'],
			},
			{
				path: '/management/architectureOperation',
				name: 'architectureOperation',
				component: resolve => require(['@/views/management/architectureOperation'], resolve),
				meta: ['人事管理', '组织结构', '新增人员'],
			},
			{
				/* 基本信息*/
				path: '/management/eRecord/architecMore/:uuid',
				name: 'architecMore',
				component: resolve => require(['@/views/management/eRecord/architecMore'], resolve),
				meta: ['人事管理', '组织结构', '电子档案'],
			},

			/*  
          状态监控
      */
			{
				path: '/conditionModule/stateRoom', //主观状态
				name: 'stateRoom',
				meta: ['状态监控'],
				component: resolve => require(['@/views/conditionModule/stateRoom'], resolve),
				children: [
					{
						path: '/conditionModule/subjectiveState', //主观状态
						name: 'subjectiveState',
						meta: ['状态监控', '主观状态'],
						component: resolve => require(['@/views/conditionModule/subjectiveState'], resolve),
					},
					{
						path: '/conditionModule/sportsExpression', //运动表现
						name: 'sportsExpression',
						meta: ['状态监控', '运动表现'],
						component: resolve => require(['@/views/conditionModule/sportsExpression'], resolve),
					},
					{
						path: '/conditionModule/weightManagement', //身体成分
						name: 'weightManagement',
						meta: ['状态监控', '身体成分'],
						component: resolve => require(['@/views/conditionModule/weightManagement'], resolve),
					},
					{
						path: '/conditionModule/functionState', //机能状态
						name: 'functionState',
						meta: ['状态监控', '机能状态'],
						component: resolve => require(['@/views/conditionModule/functionState'], resolve),
					},
				],
			},

			/*
        训练计划
      */
			{
				path: '/trainingPlan/planPage', //训练计划
				name: 'planPage',
				// meta: ['训练计划', '周训练计划'],
				component: resolve => require(['@/views/trainingPlan/planPage'], resolve),
				children: [
					{
						path: '/trainingPlan/weekly/teamPage', //训练计划 -- 周训练计划
						name: 'teamPage',
						meta: ['训练计划', '周训练计划'],
						component: resolve => require(['@/views/trainingPlan/weekly/teamPage'], resolve),
					},
					{
						path: '/trainingPlan/stage/stagePage', //训练计划 -- 阶段训练计划
						name: 'stagePage',
						meta: ['训练计划', '阶段训练计划'],
						component: resolve => require(['@/views/trainingPlan/stage/stagePage'], resolve),
					},
					{
						path: '/trainingPlan/RealTraining', //训练计划 -- 实时训练
						name: 'RealTraining',
						meta: ['训练计划', '实时训练'],
						component: resolve => require(['@/views/trainingPlan/RealTraining'], resolve),
					},
				],
			},
			{
				path: '/trainingPlan/weekly/personagePage', //训练计划 -- 周训练计划 -- 个人
				name: 'personagePage',
				meta: ['训练计划', '周训练计划'],
				component: resolve => require(['@/views/trainingPlan/weekly/personagePage'], resolve),
			},

			{
				path: '/conditionModule/subjectiveStateDetail', //主观状态 -- 详情页
				name: 'subjectiveStateDetail',
				meta: ['状态监控', '主观状态'],
				component: resolve => require(['@/views/conditionModule/subjectiveStateDetail'], resolve),
			},
			{
				path: '/conditionModule/subjectiveStateDetails', //主观状态 -- 详情页
				name: 'subjectiveStateDetails',
				meta: ['状态监控', '主观状态'],
				component: resolve => require(['@/views/conditionModule/subjectiveStateDetails'], resolve),
			},
			{
				path: '/conditionModule/sportsExpressionDetail/:uuid', //运动表现 -- 详情页
				name: 'sportsExpressionDetail',
				meta: ['状态监控', '运动表现'],
				component: resolve => require(['@/views/conditionModule/sportsExpressionDetail'], resolve),
			},
			{
				path: '/conditionModule/weightManagementDetail/:uuid', //身体成分 -- 详情页
				name: 'weightManagementDetail',
				meta: ['状态监控', '身体成分'],
				component: resolve => require(['@/views/conditionModule/weightManagementDetail'], resolve),
			},
			{
				path: '/conditionModule/functionStateDetail', //机能状态 -- 详情页
				name: 'functionStateDetail',
				meta: ['状态监控', '机能状态'],
				component: resolve => require(['@/views/conditionModule/functionStateDetail'], resolve),
			},

			/*       营养管理         */
			{
				path: '/nutritionManagement/management', //营养管理
				name: 'Management',
				meta: ['营养管理'],
				component: resolve => require(['@/views/nutritionManagement/management'], resolve),
				children: [
					{
						path: '/nutritionManagement/managementSon/TrainingNutrition',
						name: 'TrainingNutrition',
						meta: ['营养管理', '营养知识'],
						component: resolve => require(['@/views/nutritionManagement/managementSon/TrainingNutrition'], resolve),
					},
					{
						/* 营养详情 */ path: '/nutritionManagement/managementSon/trainingDetails',
						name: 'trainingDetails',
						meta: ['营养管理', '营养知识详情'],
						component: resolve => require(['@/views/nutritionManagement/managementSon/trainingDetails'], resolve),
					},
					{
						path: '/nutritionManagement/nourishmentSon/nourishmentList',
						name: 'nourishmentList',
						meta: ['营养管理', '营养品'],
						component: resolve => require(['@/views/nutritionManagement/nourishmentSon/nourishmentList'], resolve),
					},
					{
						path: '/nutritionManagement/nourishmentSon/dietaryDetails',
						name: 'dietaryDetails',
						meta: ['营养管理', '营养品详情'],
						component: resolve => require(['@/views/nutritionManagement/nourishmentSon/dietaryDetails'], resolve),
					},
				],
			},

			/*
                            训练监控结束
                        */
			/*--------------------------------------------------*/
			//系统管理模块
			{
				path: '/system/indexSetting', //指标设置
				name: 'indexSetting',
				meta: ['系统管理', '指标设置'],
				component: resolve => require(['@/views/system/indexSetting'], resolve),
			},
			{
				path: '/system/unitSet', //单位设置
				name: 'unitSet',
				meta: ['系统管理', '单位设置'],
				component: resolve => require(['@/views/system/unitSet'], resolve),
			},
			{
				path: '/system/siteSet', //地址设置
				name: 'siteSet',
				meta: ['系统管理', '地址设置'],
				component: resolve => require(['@/views/system/siteSet'], resolve),
			},

			{
				path: '/system/account', //账号设置
				name: 'account',
				meta: ['系统管理', '账号设置'],
				component: resolve => require(['@/views/system/account'], resolve),
			},
			{
				path: '/system/menu', //菜单设置
				name: 'menu',
				meta: ['系统管理', '菜单设置'],
				component: resolve => require(['@/views/system/menu'], resolve),
			},
			{
				path: '/system/feedback', //用户反馈
				name: 'feedback',
				meta: ['系统管理', '用户反馈'],
				component: resolve => require(['@/views/system/feedback'], resolve),
			},
			{
				path: '/system/loginJournal', //用户反馈
				name: 'loginJournal',
				meta: ['系统管理', '登录日志'],
				component: resolve => require(['@/views/system/loginJournal'], resolve),
			},
			// 账号权限
			{
				path: '/permission/permissionHom',
				name: ' permissionHom',
				meta: ['系统管理', '权限设置'],
				component: resolve => require(['@/views/system/permission/permissionHom'], resolve),
				children: [
					{
						path: '/system/permission/limiti', //权限设置
						name: 'limiti',
						meta: ['系统管理', '权限设置'],
						component: resolve => require(['@/views/system/permission/limiti'], resolve),
					},
					{
						path: '/system/permission/role', //角色设置
						name: 'role',
						meta: ['系统管理', '角色设置'],
						component: resolve => require(['@/views/system/permission/role'], resolve),
					},
				],
			},
			{
				path: '/dataUpload/dataList',
				name: 'limiti',
				meta: ['数据上传'],
				component: resolve => require(['@/views/dataUpload/dataList'], resolve),
			},

			//伤病设置
			{
				path: '/wound/injurySite', //伤病部位设置
				name: 'injurySite',
				meta: ['伤病管理', '伤病部位'],
				component: resolve => require(['@/views/wound/injurySite'], resolve),
			},
			{
				path: '/wound/injuryTypes', //伤病类型设置
				name: 'injuryTypes',
				meta: ['伤病管理', '损伤程度设置'],
				component: resolve => require(['@/views/wound/injuryTypes'], resolve),
			},
			{
				path: '/wound/degreeInjury', //伤病类型设置
				name: 'degreeInjury',
				meta: ['伤病管理', '伤病类型'],
				component: resolve => require(['@/views/wound/degreeInjury'], resolve),
			},
			{
				path: '/tranSetting/trainingSite', //训练部位设置
				name: 'trainingSite',
				meta: ['训练设置', '训练部位设置'],
				component: resolve => require(['@/views/tranSetting/trainingSite'], resolve),
			},
			{
				path: '/tranSetting/trainingAction', //训练动作设置
				name: 'trainingAction',
				meta: ['训练设置', '训练动作设置'],
				component: resolve => require(['@/views/tranSetting/trainingAction'], resolve),
			},
			{
				path: '/tranSetting/trainingTemplate', //训练模板设置
				name: 'trainingAction',
				meta: ['训练设置', '训练模板设置'],
				component: resolve => require(['@/views/tranSetting/trainingTemplate'], resolve),
			},
			//指标档案
			{
				path: '/record/librarySettings', //指标库设置
				name: 'librarySettings',
				meta: ['指标档案', '指标库设置'],
				component: resolve => require(['@/views/record/librarySettings'], resolve),
			},
			{
				path: '/record/unitSetting', //指标单位设置
				name: 'unitSetting',
				meta: ['指标档案', '指标库设置'],
				component: resolve => require(['@/views/record/unitSetting'], resolve),
			},
			//测试设置
			{
				path: '/testSet/bodySetting', //身体功能指标设置
				name: 'bodySetting',
				meta: ['测试设置', '身体功能指标设置'],
				component: resolve => require(['@/views/testSet/bodySetting'], resolve),
			},
			{
				path: '/testSet/strengthSetting', //力量指标设置
				name: 'strengthSetting',
				meta: ['测试设置', '力量指标设置'],
				component: resolve => require(['@/views/testSet/strengthSetting'], resolve),
			},
			{
				path: '/testSet/speedSetting', //速度指标设置
				name: 'speedSetting',
				meta: ['测试设置', '力量指标设置'],
				component: resolve => require(['@/views/testSet/speedSetting'], resolve),
			},
			{
				path: '/testSet/sensibilitySetting', //灵敏性指标设置
				name: 'sensibilitySetting',
				meta: ['测试设置', '灵敏性指标设置'],
				component: resolve => require(['@/views/testSet/sensibilitySetting'], resolve),
			},
			{
				path: '/testSet/outburstSetting', //爆发力指标设置
				name: 'outburstSetting',
				meta: ['测试设置', '爆发力指标设置'],
				component: resolve => require(['@/views/testSet/outburstSetting'], resolve),
			},
			{
				path: '/testSet/enduranceSetting', //耐力指标设置
				name: 'enduranceSetting',
				meta: ['测试设置', '耐力指标设置'],
				component: resolve => require(['@/views/testSet/enduranceSetting'], resolve),
			},
			//状态设置
			{
				path: '/statusSite/bodyCation', //身体机能指标
				name: 'bodyCation',
				meta: ['状态设置', '身体指标分类设置'],
				component: resolve => require(['@/views/statusSite/bodyCation'], resolve),
			},

			// 营养设置
			{
				path: '/nutritionSet/nutritionList',
				name: ' nutritionList',
				meta: ['营养设置'],
				component: resolve => require(['@/views/nutritionSet/nutritionList'], resolve),
				children: [
					{
						path: '/nutritionSet/knowledgeList',
						name: 'knowledgeList',
						meta: ['营养设置', '营养品分类设置新增'],
						component: resolve => require(['@/views/nutritionSet/knowledgeList'], resolve),
					},
					{
						path: '/nutritionSet/knowledgeAdd',
						name: 'knowledgeAdd',
						meta: ['营养设置', '营养知识分类设置新增'],
						component: resolve => require(['@/views/nutritionSet/knowledgeAdd'], resolve),
					},
					{
						path: '/nutritionSet/knowledgeSet',
						name: 'knowledgeSet',
						meta: ['营养设置', '营养知识分类设置'],
						component: resolve => require(['@/views/nutritionSet/knowledgeSet'], resolve),
					},
					{
						path: '/nutritionSet/nutritionLibrary',
						name: 'nutritionLibrary',
						meta: ['营养设置', '营养品库'],
						component: resolve => require(['@/views/nutritionSet/nutritionLibrary'], resolve),
					},
					{
						path: '/nutritionSet/nutritionLibraryAdd',
						name: 'nutritionLibraryAdd',
						meta: ['营养品', '营养品库新增'],
						component: resolve => require(['@/views/nutritionSet/nutritionLibraryAdd'], resolve),
					},
					{
						path: '/nutritionSet/nutritionLibrarySet',
						name: 'nutritionLibrarySet',
						meta: ['营养设置', '营养品分类设置'],
						component: resolve => require(['@/views/nutritionSet/nutritionLibrarySet'], resolve),
					},
					{
						path: '/nutritionSet/nutritionLibraryAddS',
						name: 'nutritionLibraryAddS',
						meta: ['营养设置', '营养品分类编辑'],
						component: resolve => require(['@/views/nutritionSet/nutritionLibraryAddS'], resolve),
					},
				],
			},

			// 统计分析
			{
				path: '/analysis/statisticalAnalysis',
				name: 'statisticalAnalysis',
				meta: ['统计分析'],
				component: resolve => require(['@/views/analysis/statisticalAnalysis'], resolve),
				// redirect: '/analysis/individualAnalysis?type=1',
				children: [
					{
						path: '/analysis/individualAnalysis',
						name: 'individualAnalysis',
						meta: ['统计分析', '个体分析'],
						component: resolve => require(['@/views/analysis/individualAnalysis'], resolve),
					},
					{
						path: '/analysis/jointAnalysis',
						name: 'jointAnalysis',
						meta: ['统计分析', '联合分析'],
						component: resolve => require(['@/views/analysis/jointAnalysis'], resolve),
					},
					{
						path: '/analysis/reportManagement',
						name: 'reportManagement',
						meta: ['统计分析', '报告管理'],
						component: resolve => require(['@/views/analysis/reportManagement'], resolve),
					},
					// 设备数据管理
					// {
					// 	path: '/DataDocking/dataAccess',
					// 	name: 'dataAccess',
					// 	component: resolve => require(['@/views/DataDocking/docking'], resolve),
					// 	meta: ['设备数据管理', '数据接入'],
					// 	children: [
					// 		{
					// 			path: '/DataDocking/functionalMent',
					// 			name: 'functionalMent',
					// 			component: resolve => require(['@/views/DataDocking/funcEquipment'], resolve),
					// 			meta: ['设备数据管理', '数据接入', '机能设备'],
					// 		},
					// 		{
					// 			path: '/DataDocking/PhysicalMent',
					// 			name: 'PhysicalMent',
					// 			component: resolve => require(['@/views/DataDocking/PhysEquipment'], resolve),
					// 			meta: ['设备数据管理', '数据接入', '体能设备'],
					// 		},
					// 	],
					// },
				],
			},

			{
				path: '/testSet/SampleManager',
				name: 'SampleManager',
				component: resolve => require(['@/views/DataDocking/SampleManager'], resolve),
				meta: ['系统管理', '样本管理'],
				children: [
					{
						path: '/SampleManager/functionalMent',
						name: 'functionalMent',
						component: resolve => require(['@/views/DataDocking/SampleManager'], resolve),
						meta: ['系统管理', '样本管理', '机能设备'],
					},
				],
			},

			{
				path: '/analysis/reportManagementLook',
				name: 'reportManagementLook',
				meta: ['统计分析', '查看报告管理'],
				component: resolve => require(['@/views/analysis/reportManagementLook'], resolve),
			},

			/*--------------------------------------------------*/
			//人事管理 综合设置开始
			{
				path: '/management/postSetting', //岗位设置
				name: 'postSetting',
				meta: ['人事管理', '岗位设置'],
				component: resolve => require(['@/views/management/postSetting'], resolve),
			},
			{
				path: '/management/fileType', //档案类型
				name: 'fileType',
				meta: ['人事管理', '档案类型'],
				component: resolve => require(['@/views/management/fileType'], resolve),
			},
			{
				path: '/management/departmentSetting', //运动队设置
				name: 'departmentSetting',
				meta: ['人事管理', '运动队设置'],
				component: resolve => require(['@/views/management/departmentSetting'], resolve),
			},
			{
				path: '/management/sportsSettings', //运动项目设置
				name: 'sportsSettings',
				meta: ['人事管理', '运动项目设置'],
				component: resolve => require(['@/views/management/sportsSettings'], resolve),
			},
			{
				path: '/interWound/International', //伤病管理
				name: 'International',
				meta: ['伤病管理'],
				component: resolve => require(['@/views/interWound/index'], resolve),
			},
			{
				path: '/interWound/interDeparment/:uuid', //个人伤病记录
				name: 'interDeparment',
				meta: ['伤病管理', '个人伤病记录'],
				component: resolve => require(['@/views/interWound/interDeparment'], resolve),
			},
			{
				path: '/textItem/textManagement', //测试管理
				name: 'textManagement',
				meta: ['测试管理'],
				component: resolve => require(['@/views/textItem/textManagement'], resolve),
				children: [
					{
						path: '/textItem/bodyFunction', //身体功能测试
						name: 'bodyFunction',
						meta: ['测试管理', '身体功能测试'],
						component: resolve => require(['@/views/textItem/bodyFunction'], resolve),
					},
					{
						path: '/textItem/strengthTest', //力量测试
						name: 'strengthTest',
						meta: ['测试管理', '力量测试'],
						component: resolve => require(['@/views/textItem/strengthTest'], resolve),
					},
					{
						path: '/textItem/speedTest', //速度测试
						name: 'speedTest',
						meta: ['测试管理', '速度测试'],
						component: resolve => require(['@/views/textItem/speedTest'], resolve),
					},
					{
						path: '/textItem/sensibilityText', //灵敏性测试
						name: 'sensibilityText',
						meta: ['测试管理', '灵敏性测试'],
						component: resolve => require(['@/views/textItem/sensibilityText'], resolve),
					},
					{
						path: '/textItem/explosiveText', //爆发力测试
						name: 'explosiveText',
						meta: ['测试管理', '爆发力测试'],
						component: resolve => require(['@/views/textItem/explosiveText'], resolve),
					},
					{
						path: '/textItem/enduranceText', //耐力测试
						name: 'enduranceText',
						meta: ['测试管理', '耐力测试'],
						component: resolve => require(['@/views/textItem/enduranceText'], resolve),
					},
					{
						path: '/textItem/lactateText', //血乳酸测试
						name: 'lactateText',
						meta: ['测试管理', '血乳酸测试'],
						component: resolve => require(['@/views/textItem/lactateText'], resolve),
					},
				],
			},
			{
				path: '/textItem/bodyFunctionAdd',
				name: 'bodyFunctionAdd',
				component: resolve => require(['@/views/textItem/bodyFunctionAdd'], resolve),
			},
			{
				path: '/textItem/strengthTestAdd',
				name: 'strengthTestAdd',
				component: resolve => require(['@/views/textItem/strengthTestAdd'], resolve),
			},
			{
				path: '/textItem/enduranceTextAdd',
				name: 'enduranceTextAdd',
				component: resolve => require(['@/views/textItem/enduranceTextAdd'], resolve),
			},
			{
				path: '/textItem/sensibilityTextAdd',
				name: 'sensibilityTextAdd',
				component: resolve => require(['@/views/textItem/sensibilityTextAdd'], resolve),
			},
			{
				path: '/textItem/explosiveTextAdd',
				name: 'explosiveTextAdd',
				component: resolve => require(['@/views/textItem/explosiveTextAdd'], resolve),
			},
			{
				path: '/textItem/speedTestAdd',
				name: 'speedTestAdd',
				component: resolve => require(['@/views/textItem/speedTestAdd'], resolve),
			},
			{
				path: '/textItem/lactateTextAdd',
				name: 'lactateTextAdd',
				component: resolve => require(['@/views/textItem/lactateTextAdd'], resolve),
			},
			{
				path: '/textItem/lactateTextDetail',
				name: 'lactateTextDetail',
				component: resolve => require(['@/views/textItem/lactateTextDetail'], resolve),
			},
			{
				path: '/textItem/lactateTextList',
				name: 'lactateTextList',
				component: resolve => require(['@/views/textItem/lactateTextList'], resolve),
			},
		],
	},
	{
		path: '/',
		name: 'home',
		redirect: '/management/architecture',
		meta: {
			title: '',
		},
		component: resolve => require(['@/views/home.vue'], resolve),
		children: [
			// 营养设置
			{
				path: '/nutritionSet',
				name: ' nutritionList',
				meta: ['营养设置'],
				component: resolve => require(['@/views/nutritionSet/nutritionList'], resolve),
				children: [
					{
						path: '/nutritionSet/knowledgeList/knowledgeAddS',
						name: 'knowledgeAddS',
						meta: ['营养设置', '营养品分类设置新增'],
						component: resolve => require(['@/views/nutritionSet/knowledgeAddS'], resolve),
					},
				],
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: resolve => require(['@/views/login/login.vue'], resolve),
	},
	{
		path: '/smartCockpit',
		name: 'smartCockpit',
		component: resolve => require(['@/views/smartCockpit.vue'], resolve),
	},
	{
		path: '/EquipmentPrint',
		name: 'EquipmentPrint',
		component: resolve => require(['@/views/DataDocking/EquipmentPrint.vue'], resolve),
	},
	{
		path: '/lookMentPrint',
		name: 'lookMentPrint',
		component: resolve => require(['@/views/DataDocking/lookMentPrint.vue'], resolve),
	},
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
})

export default router
