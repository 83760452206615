import staffList from './staffList/staffList.vue'

const loading = {
  install: function (Vue) {
    //卡片组件
    Vue.component('staffList', staffList)
  }
  // loading'这就是后面可以使用的组件的名字，install是vue实例上默认的一个方法
}

export default loading
