let rules = {
    "name": [
        {
            "required": true,
            "message": "姓名不能为空",
            "trigger": "change"
        },
        {
            "min": 2,
            "max": 10,
            "message": "姓名长度在 2 到 10 个字符",
            "trigger": "change"
        }
    ],
    "noEmpty": {
        "required": true,
        "message": "请选择活动区域",
        "trigger": "change"
    },
    "letter": {
        "required": true,
        "message": "请输入字母",
        "trigger": "change",
        'pattern': /[a-z]/
    },
    "English": {
        "required": true,
        "message": "请输入字母",
        "trigger": "change",
        'pattern': /[a-zA-Z]/
    }, 
    "email": {
        "required": true,
        "message": "邮箱格式不正确",
        "trigger": "change",
        'pattern': /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
    },
    "phone": {
        "required": true,
        "message": "手机格式不正确",
        "pattern": /^1[345789]\d{9}$/,
        "trigger": "change"
    },
    "idCard": {
        "required": true,
        "message": "身份证号格式不正确",
        "pattern": /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        "trigger": "change"
    },
    "isNumber": {
        "required": true,
        "message": "数字格式不正确",
        "pattern": /^[0-9]*$/,
        "trigger": "change"
    }
}
export default rules;