import { Message } from "element-ui"

const uilts = {
    message(data) {
        Message({
            type: data ? data.type : 'success',
            message: data ? data.message : '操作成功'
        })
    },
    time(date) {
        if (date == undefined) {
            return "无"
        }
        // 比如需要这样的格式 yyyy-MM-dd hh:mm:ss
        var date = new Date(date);
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        return Y + M + D
    },
    getMenuType(index){
        let data = [
            {
                name:'体能监控',
                value:1,
                mate:['训练监控','体能监控测试']
            },
            {
                name:'技能监控',
                value:2,
                mate:['训练监控','技能监控测试']
            },
            {
                name:'智能监控',
                value:3,
                mate:['训练监控','智能监控测试']
            },
            {
                name:'恢复监控',
                value:4,
                mate:['训练监控','恢复监控测试']
            },
            {
                name:'身体形态评估',
                value:5,
                mate:['训练评估','身体形态评估测试']
            },
            {
                name:'身体机能评估',
                value:6,
                mate:['训练评估','身体机能评估测试']
            },
            {
                name:'身体功能评估',
                value:7,
                mate:['训练评估','身体功能评估测试']
            },
            {
                name:'体能评估',
                value:8,
                mate:['训练评估','体能评估测试']
            },
            {
                name:'技能评估',
                value:9,
                mate:['训练评估','技能评估测试']
            },
            {
                name:'智能评估',
                value:10,
                mate:['训练评估','智能评估测试']

            },
            {
                name:'恢复评估',
                value:11,
                mate:['训练评估','恢复评估测试']
            },
            {
                name:'基础体能十项指标',
                value:12,
                mate:['训练评估','基础体能十项指标']
            },

        ]
        return data[index]
    },
    getSettingStr(index){
        let data = [
            {
                name:'体能监控指标',
                level:'1',
                meta:['训练监控','监控设置','体能监控指标设置']
            },
            {
                name:'身体形态监控',
                level:'2',
                meta:['训练监控','监控设置','体能监控指标设置']
            },
            {
                name:'身体机能监控',
                level:'2',
                meta:['训练监控','监控设置','体能监控指标设置']
            },
            {
                name:'身体素质监控',
                level:'2',
                meta:['训练监控','监控设置','体能监控指标设置']
            },
            {
                name:'技能监控',
                level:'1',
                meta:['训练监控','监控设置','技能监控指标设置']
            },
            {
                name:'智能监控',
                level:'1',
                meta:['训练监控','监控设置','智能监控指标设置']
            },
            {
                name:'恢复监控',
                level:'1',
                meta:['训练监控','监控设置','恢复监控指标设置']
            },
            {
                name:'身体形态评估',
                level:'1',
                meta:['训练评估','评估设置','身体形态指标设置']
            },
            {
                name:'身体机能评估',
                level:'1',
                meta:['训练评估','评估设置','身体机能指标设置']
            },
            {
                name:'身体功能评估',
                level:'1',
                meta:['训练评估','评估设置','身体功能指标设置']
            },
            {
                name:'其他分类指标',
                level:'2',
                meta:['训练评估','评估设置','体能评估指标设置']
            },
            {
                name:'技能评估',
                level:'1',
                meta:['训练评估','评估设置','技能评估指标设置']
            },
            {
                name:'智能评估',
                level:'1',
                meta:['训练评估','评估设置','智能评估指标设置']
            },
            {
                name:'恢复评估',
                level:'1',
                meta:['训练评估','评估设置','恢复评估指标设置']
            },
            {
                name:'基础体能十项指标',
                level:'2',
                meta:['训练评估','评估设置','体能评估指标设置']
            },
        ]
        return data[index]
    },
    data() {
        let data = [
            { key: 'name', value: '名称', },
            { key: 'edible_part', value: '可食部分', },
            { key: 'energy', value: '能量', },
            { key: 'fat', value: '脂肪', },
            { key: 'dietary_fiber', value: '膳食纤维', },
            { key: 'carbohydrate', value: '碳水', },
            { key: 'category', value: '食物分类', },
            { key: 'cooking_food', value: '烹调食物类', },
            { key: 'common_ingredients', value: '常见食材', },
            { key: 'retinol', value: '视黄醇', },
            { key: 'vb1', value: '硫胺素(VB1)', },
            { key: 'vb2', value: '核黄素(VB2)', },
            { key: 'vpp', value: '尼克酸(烟酸,VPP)', },
            { key: 'moisture', value: '水分', },
            { key: 've', value: '维生素E', },
            { key: 'sodium', value: '钠', },
            { key: 'iron', value: '铁', },
            { key: 'gi', value: 'gi', },
            { key: 'gl', value: 'gl', },
            { key: 'minute_sort', value: '食物细分类', },
            { key: 'reclassify', value: '食物三级分类', },
            { key: 'vc', value: '抗坏血酸(VC)', },
            { key: 'cholesterol', value: '胆固醇', },
            { key: 'ash', value: '灰分', },
            { key: 'va', value: 'va', },
            { key: 'renieratene', value: '胡萝卜素', },
            { key: 'potassium', value: '钾', },
            { key: 'magnesium', value: '镁', },
            { key: 'manganese', value: '锰', },
            { key: 'cuprum', value: '铜', },
            { key: 'phosphorus', value: '磷', },
            { key: 'protein', value: '蛋白质' },
            { key: 'selenium', value: '硒', },
            { key: 'calcium', value: '钙' },
            { key: 'zinc', value: '锌' }
        ]
        return data
    },
    data1() {
        let data1 = [
            { key: 'name', value: '名称', },
            { key: 'edible_part', value: '可食部分', },
            { key: 'energy', value: '能量(kcal)', },
            { key: 'fat', value: '脂肪(g)', },
            { key: 'dietary_fiber', value: '膳食纤维(g)', },
            { key: 'carbohydrate', value: '碳水化合物(g)', },
            { key: 'category', value: '食物分类', },
            { key: 'cooking_food', value: '烹调食物类', },
            { key: 'common_ingredients', value: '常见食材', },
            { key: 'retinol', value: '视黄醇(mg)', },
            { key: 'vb1', value: '硫胺素(mg)', },
            { key: 'vb2', value: '核黄素(mg)', },
            { key: 'vpp', value: '尼克酸(烟酸,VPP)(mg)', },
            { key: 'moisture', value: '水分', },
            { key: 've', value: '维生素E(mg)', },
            { key: 'sodium', value: '钠(mg)', },
            { key: 'iron', value: '铁(mg)', },
            { key: 'gi', value: 'gi', },
            { key: 'gl', value: 'gl', },
            { key: 'minute_sort', value: '食物细分类', },
            { key: 'reclassify', value: '食物三级分类', },
            { key: 'vc', value: '抗坏血酸(VC)(mg)', },
            { key: 'cholesterol', value: '胆固醇', },
            { key: 'ash', value: '灰分', },
            { key: 'va', value: 'va(ugRE)', },
            { key: 'renieratene', value: '胡萝卜素', },
            { key: 'potassium', value: '钾(mg)', },
            { key: 'magnesium', value: '镁(mg)', },
            { key: 'manganese', value: '锰(mg)', },
            { key: 'cuprum', value: '铜(mg)', },
            { key: 'phosphorus', value: '磷(mg)', },
            { key: 'protein', value: '蛋白质(g)' },
            { key: 'selenium', value: '硒(mg)', },
            { key: 'calcium', value: '钙(mg)' },
            { key: 'zinc', value: '锌(mg)' }
        ]
        return data1
    },

    foodData() {
        let foodData = [
            { key: 'name', value: '名称', },
            { key: 'edible_part', value: '可食部分', },
            { key: 'energy', value: '能量', },
            { key: 'protein', value: '蛋白质' },
            { key: 'fat', value: '脂肪', },
            { key: 'dietary_fiber', value: '膳食纤维', },
            { key: 'carbohydrate', value: '碳水', },
            { key: 'category', value: '食物分类', },
            { key: 'minute_sort', value: '食物细分类', },
            { key: 'cooking_food', value: '烹调食物类', },
            { key: 'common_ingredients', value: '常见食材', },
        ]
        return foodData;
    },
    classification() {


        let options = [{
                value: 1,
                label: '谷类',
                children: [{
                        value: 1,
                        label: '米及制品'
                    },
                    {
                        value: 2,
                        label: '面及制品'
                    },
                    {
                        value: 3,
                        label: '全谷物'
                    },
                    {
                        value: 4,
                        label: '杂豆类'
                    },
                    {
                        value: 5,
                        label: '薯类'
                    },
                    {
                        value: 6,
                        label: '淀粉类'
                    },

                ]
            },
            {
                value: 2,
                label: '畜禽类',
                children: [{
                        value: 7,
                        label: '禽类及制品',
                        children: [{
                                label: '禽肉',
                                value: 1,
                            },
                            {
                                label: '内脏',
                                value: 2,
                            },
                        ]
                    },
                    {
                        value: 8,
                        label: '猪肉及制品',
                        children: [{
                                label: '猪肉',
                                value: 3,
                            },
                            {
                                label: '内脏',
                                value: 4,
                            },
                        ]

                    },
                    {
                        value: 9,
                        label: '牛肉及制品',
                        children: [{
                                label: '牛肉',
                                value: 5,
                            },
                            {
                                label: '内脏',
                                value: 6,
                            },
                        ]
                    },
                    {
                        value: 10,
                        label: '羊肉及制品',
                        children: [{
                                label: '羊肉',
                                value: 7,
                            },
                            {
                                label: '内脏',
                                value: 8,
                            },
                        ]
                    },
                    {
                        value: 11,
                        label: '其他肉制品'
                    },
                ]
            },
            {

                value: 3,
                label: '海产类',
                children: [{
                        value: 12,
                        label: '鱼'
                    },
                    {
                        value: 13,
                        label: '虾'
                    },
                    {
                        value: 14,
                        label: '蟹'
                    },
                    {
                        value: 15,
                        label: '贝'
                    },
                    {
                        value: 16,
                        label: '其他'
                    },
                ]
            },
            {
                value: 4,
                label: '蔬菜类',
                children: [{
                        value: 17,
                        label: '深色蔬菜'
                    },
                    {
                        value: 18,
                        label: '浅色蔬菜'
                    },
                    {
                        value: 19,
                        label: '海藻类'
                    },
                    {
                        value: 20,
                        label: '菌菇类'
                    },
                ]
            },
            {
                value: 5,
                label: '水果类',
                children: [{
                        value: 21,
                        label: '仁果类'
                    },
                    {
                        value: 22,
                        label: '核果类'
                    },
                    {
                        value: 23,
                        label: '浆果类'
                    },
                    {
                        value: 24,
                        label: '柑橘类'
                    },
                    {
                        value: 25,
                        label: '热带水果'
                    },
                    {
                        value: 26,
                        label: '瓜果类'
                    },

                ]
            },
            {
                value: 6,
                label: '蛋及制品',
            },
            {
                value: 7,
                label: '奶及制品',
                children: [{
                        value: 27,
                        label: '鲜奶类'
                    },
                    {
                        value: 28,
                        label: '酸奶类'
                    },
                    {
                        value: 29,
                        label: '其他'
                    },
                ]
            },
            {
                value: 8,
                label: '大豆及制品',
            },
            {
                value: 9,
                label: '坚果',
            },
            {
                value: 10,
                label: '油脂',
            },
            {
                value: 11,
                label: '调味品及蜜饯类',
                children: [{
                        value: 30,
                        label: '调味品'
                    },
                    {
                        value: 31,
                        label: '蜜饯类'
                    },
                ]
            },
            {
                value: 12,
                label: '水及饮料',
                children: [{
                        value: 32,
                        label: '水'
                    },
                    {
                        value: 33,
                        label: '饮料'
                    },
                ]
            },
        ]
        return options;
    },
    recipe() {


        let options = [{
                value: 1,
                label: '主食'
            },
            {
                value: 2,
                label: '素菜'
            },
            {
                value: 3,
                label: '荤菜'
            },
            {
                value: 4,
                label: '蛋奶坚果'
            },
            {
                value: 5,
                label: '水果'
            },
            {
                value: 6,
                label: '汤饮'
            },
        ];
        return options;
    },
    nationOptions() {
        let nationOptions = [{
                value: 1,
                label: "汉族"
            },
            {
                value: 2,
                label: "壮族"
            },
            {
                value: 3,
                label: "满族"
            },
            {
                value: 4,
                label: "回族"
            },
            {
                value: 5,
                label: "苗族"
            },
            {
                value: 6,
                label: "维吾尔族"
            },
            {
                value: 7,
                label: "土家族"
            },
            {
                value: 8,
                label: "彝族"
            },
            {
                value: 9,
                label: "蒙古族"
            },
            {
                value: 10,
                label: "藏族"
            },
            {
                value: 11,
                label: "布依族"
            },
            {
                value: 12,
                label: "侗族"
            },
            {
                value: 13,
                label: "瑶族"
            },
            {
                value: 14,
                label: "朝鲜族"
            },
            {
                value: 15,
                label: "白族"
            },
            {
                value: 16,
                label: "哈尼族"
            },
            {
                value: 17,
                label: "哈萨克族"
            },
            {
                value: 18,
                label: "黎族"
            },
            {
                value: 19,
                label: "傣族"
            },
            {
                value: 20,
                label: "畲族"
            },
            {
                value: 21,
                label: "傈僳族"
            },
            {
                value: 22,
                label: "仡佬族"
            },
            {
                value: 23,
                label: "东乡族"
            },
            {
                value: 24,
                label: "高山族"
            },
            {
                value: 25,
                label: "拉祜族"
            },
            {
                value: 26,
                label: "水族"
            },
            {
                value: 27,
                label: "佤族"
            },
            {
                value: 28,
                label: "纳西族"
            },
            {
                value: 29,
                label: "羌族"
            },
            {
                value: 30,
                label: "土族"
            },
            {
                value: 31,
                label: "仫佬族"
            },
            {
                value: 32,
                label: "锡伯族"
            },
            {
                value: 33,
                label: "柯尔克孜族"
            },
            {
                value: 34,
                label: "达斡尔族"
            },
            {
                value: 35,
                label: "景颇族"
            },
            {
                value: 36,
                label: "毛南族"
            },
            {
                value: 37,
                label: "撒拉族"
            },
            {
                value: 38,
                label: "塔吉克族"
            },
            {
                value: 39,
                label: "阿昌族"
            },
            {
                value: 40,
                label: "普米族"
            },
            {
                value: 41,
                label: "鄂温克族"
            },
            {
                value: 42,
                label: "怒族"
            },
            {
                value: 43,
                label: "京族"
            },
            {
                value: 44,
                label: "基诺族"
            },
            {
                value: 45,
                label: "德昂族"
            },
            {
                value: 46,
                label: "保安族"
            },
            {
                value: 47,
                label: "俄罗斯族"
            },
            {
                value: 48,
                label: "裕固族"
            },
            {
                value: 49,
                label: "乌兹别克族"
            },
            {
                value: 50,
                label: "门巴族"
            },
            {
                value: 51,
                label: "鄂伦春族"
            },
            {
                value: 52,
                label: "独龙族"
            },
            {
                value: 53,
                label: "塔塔尔族"
            },
            {
                value: 54,
                label: "赫哲族"
            },
            {
                value: 55,
                label: "珞巴族"
            },
            {
                value: 56,
                label: "布朗族"
            }
        ];
        return nationOptions
    },
    sleepOptions() {
      let options = [{
        label: '3'
      }, {
        label: '3.5'
      }, {
        label: '4'
      }, {
        label: '4.5'
      }, {
        label: '5'
      }, {
        label: '5.5'
      }, {
        label: '6'
      }, {
        label: '6.5'
      }, {
        label: '7'
      }, {
        label: '7.5'
      }, {
        label: '8'
      }, {
        label: '8.5'
      }, {
        label: '9'
      }, {
        label: '9.5'
      }, {
        label: '10'
      }, {
        label: '10.5'
      }, {
        label: '11'
      }, {
        label: '11.5'
      }, {
        label: '12'
      }, {
        label: '12.5'
      }, {
        label: '13'
      }, {
        label: '13.5'
      }, {
        label: '14'
      }, {
        label: '14.5'
      }, {
        label: '15'
      }]
      return options
    }
}

export default uilts